<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      [WIP]This is an dashborad
    </div>
  </div>
</template>
<script>
  export default {
    mounted() {
      let login_code = window.localStorage.getItem('login_code');
      if (!window.localStorage.getItem('login_code')) {
        this.$router.push({
          path: '/login',
        });
      }
    },
    methods: {}
  }
</script>


